
/*h2 {*/
/*    margin: 40px 0 0;*/
/*}*/

.heading {
    text-align: center;
    background-color: #eae6df;
    padding: 50px 100px;
    font-family: 'Oswald', sans-serif;
    font-size: 2em;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.5);
}

.menu {
    list-style: none;
    padding-left: 0;
}

.menu-item {
    padding-left: 35px;
    text-transform: uppercase;
    color: white;
}

.link {
    color: blue;
}

.container {
    max-width: 1024px;
    margin: 70px auto 0;
}
